<template>
  <el-aside :width="isCollapse ? '80px' : '216px'" class="aside">
    <el-menu
      :unique-opened="true"
      :default-active="menuIndex"
      class="admin-menu"
      @select="handleMenuIndex"
      :style="styleHeight"
      background-color="#353535"
      text-color="#fff"
      active-text-color="#409EFF"
      :router="true"
      :collapse="isCollapse"
    >
      <div class="open" @click="handOpen">
        <i
          :class="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        ></i>
      </div>
      <el-submenu
        v-for="(item, index) in menuList"
        :key="index"
        :index="(index + 1).toString()"
      >
        <template #title>
          <i :class="item.icon ? item.icon : 'el-icon-menu'"></i>
          <span>{{ zn ? item.menu_name : item.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(items, idx) in item.children"
            :key="idx"
            :index="index + 1 + '-' + (idx + 1)"
            :route="{
              name: items.name,
              params: { type: JSON.stringify(items.children) },
            }"
            @click="handMenuItem(index, idx)"
            >{{ zn ? items.menu_name : items.name }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  data() {
    return {
      styleHeight: {},
      menuIndex: this.$store.state.menuIndex,
      menuList: [
        {
          menu_name: "系统管理",
          icon: "el-icon-location",
          children: [
            {
              menu_name: "首页",
              name: "Home",
            },
          ],
        },
      ],
      zn: "",
      isCollapse: true,
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.bus.$on("menuIndex", (val) => {
          this.menuIndex = val;
        });
      },
      immediate: true,
    },
  },
  methods: {
    handOpen() {
      this.isCollapse = !this.isCollapse;
      this.bus.$emit("isCollapse", this.isCollapse);
    },
    handleMenuIndex(e) {
      this.$store.commit("changeMenuIndex", e);
    },
    handMenuItem(index, idx) {
      let arr = this.$store.state.tagArr;
      let arrItem = {
        name: this.menuList[index].children[idx].menu_name,
        cname: this.menuList[index].children[idx].name,
        url: this.menuList[index].children[idx].name,
        index: index + 1 + "-" + (idx + 1),
      };
      if (arr.length > 0) {
        if (this.zn) {
          let isAdd = arr.every(
            (item) => item.name !== this.menuList[index].children[idx].menu_name
          );
          if (isAdd) {
            arr.push(arrItem);
          }
        } else {
          let isAdd = arr.every(
            (item) => item.cname !== this.menuList[index].children[idx].name
          );
          if (isAdd) {
            arr.push(arrItem);
          }
        }
      } else {
        arr.push(arrItem);
      }
      this.$store.commit("changeTagArr", arr);
      this.$store.commit("changePhone", 0);
    },
  },
  created() {
    this.bus.$on("changeLang", (val) => {
      this.zn = val;
    });
  },
  mounted() {
    this.zn = this.$store.state.zn;
    this.menuList = JSON.parse(localStorage.getItem("menuList"));
    let h = document.documentElement.clientHeight - 80;
    this.styleHeight = {
      height: h + "px",
    };
  },
};
</script>

<style lang="scss">
.aside {
  position: fixed;
  top: 80px;
  z-index: 10;
  padding-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  .admin-menu {
    position: relative;
    border-right: none;
    border-bottom-right-radius: 20px;

    .open {
      position: absolute;
      width: 16px;
      height: 40px;
      top: 50%;
      right: -16px;
      margin-top: -20px;
      background: #353535;
      z-index: 10;
      border-radius: 0 8px 8px 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
