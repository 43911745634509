<template>
  <el-header class="header" height="80px">
    <div class="header-title">
      <el-image
        class="header-logo"
        :src="require('../../assets/image/logo.png')"
        fit="cover"
      ></el-image>
      <div>Uummii在线教育数据管理平台</div>
    </div>
    <div v-if="isShow" class="header-admin">
      <el-button class="header-lang" type="text" circle @click="handLang">{{
        zn ? "EN" : "中"
      }}</el-button>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link admin-name">
          {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="(item, index) in dropdownArr"
              :key="index"
              class="admin-item"
              :command="item.command"
              >{{ item.title }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-dialog title="提示" v-model="dialogVisible" width="30%">
      <span>您确定要退出吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handLoginOut">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-header>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      username: "管理员",
      dialogVisible: false,
      dropdownArr: [
        {
          title: "修改密码",
          command: "amendPassword",
        },
        {
          title: "系统首页",
          command: "Home",
        },
        {
          title: "退出登录",
          command: "true",
        },
      ],
      zn: true,
    };
  },
  methods: {
    handLang() {
      this.zn = !this.zn;
      if (this.zn) {
        this.$store.commit("changZn", "zh");
      } else {
        this.$store.commit("changZn", "en");
      }
      this.bus.$emit("changeLang", this.zn);
      this.$store.commit("changeLang", this.zn);
      location.reload();
    },
    handleCommand(e) {
      if (e === "true") {
        this.dialogVisible = true;
      } else {
        this.$store.commit("changeMenuIndex", "");
        if (e === "amendPassword") {
          let arr = this.$store.state.tagArr;
          let arrItem = {
            name: "修改密码",
            url: e,
            index: "",
          };
          if (arr.length > 0) {
            let isAdd = arr.every((item) => item.name !== "修改密码");
            if (isAdd) {
              arr.push(arrItem);
            }
          } else {
            arr.push(arrItem);
          }
          this.$store.commit("changeTagArr", arr);
        }
        this.$router.push({ name: e });
      }
    },
    handLoginOut() {
      this.dialogVisible = false;
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("menuList");
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    this.username = localStorage.getItem("username");
    this.zn = this.$store.state.zn;
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 50px;
  background: rgb(53, 53, 53);
  z-index: 10;

  .header-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;

    .header-logo {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      background: #353535;
    }
  }

  .header-admin {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .header-lang {
      margin-right: 10px;
      color: #fff;
    }

    .admin-name {
      line-height: normal;
      font-size: 16px;
      cursor: pointer;
      color: #fff;
    }
  }
}

.admin-item {
  font-size: 16px;
}
</style>
