<template>
  <div class="bread-crumb" :style="isCollapse">
    <div class="bread-content" ref="breadC">
      <div
        :class="[
          'bread-group',
          isRight ? 'bread-group-right' : 'bread-group-left',
        ]"
        ref="breadG"
      >
        <el-tag
          class="top-tag"
          type="success"
          effect="dark"
          @click="handRouteHome"
          >{{ zn ? "首页" : "Home" }}</el-tag
        >
        <el-tag
          v-for="(item, index) in tagArr"
          :key="index"
          class="top-tag"
          type="success"
          effect="dark"
          closable
          @click="handTagItem(index)"
          @close="handCloseTagItem(index)"
          >{{ zn ? item.name : item.cname }}</el-tag
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagArr: [],
      zn: "",
      isRight: false,
      isCollapse: {
        left: "64px",
        width: "calc(100% - 64px)",
      },
    };
  },
  watch: {
    $route: {
      handler: function () {
        let breadC = this.$refs.breadC.offsetWidth;
        let breadG = this.$refs.breadG.offsetWidth;
        if (breadG > breadC) {
          this.isRight = true;
        } else {
          this.isRight = false;
        }
      },
    },
  },
  methods: {
    handRouteHome() {
      this.$store.commit("changeMenuIndex", "");
      this.$router.push("/");
    },
    handTagItem(e) {
      this.$store.commit("changeMenuIndex", this.tagArr[e].index);
      this.bus.$emit("menuIndex", this.tagArr[e].index);
      this.$router.push({ name: this.tagArr[e].url });
    },
    handCloseTagItem(e) {
      let array = this.$store.state.tagArr;
      array.splice(e, 1);
      this.$store.commit("changeTagArr", array);
      if (array.length > 0) {
        this.$store.commit("changeMenuIndex", array[array.length - 1].index);
        this.bus.$emit("menuIndex", array[array.length - 1].index);
        this.$router.push({ name: array[array.length - 1].url });
      } else {
        this.handRouteHome();
      }
    },
  },
  created() {
    this.bus.$on("changeLang", (val) => {
      this.zn = val;
    });
    this.bus.$on("isCollapse", (val) => {
      let obj = {
        left: val ? "64px" : "200px",
        width: val ? "calc(100% - 64px)" : "calc(100% - 200px)",
      };
      this.isCollapse = obj;
    });
  },
  mounted() {
    this.tagArr = this.$store.state.tagArr;
    this.zn = this.$store.state.zn;
  },
};
</script>

<style lang="scss">
.bread-crumb {
  position: fixed;
  top: 80px;
  width: -webkit-calc(100% - 64px);
  width: -moz-calc(100% - 64px);
  height: 43px;
  padding: 5px 15px;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  z-index: 9;

  .bread-content {
    position: relative;
    height: 100%;

    .bread-group {
      position: absolute;
      width: auto;
      top: 0;
      z-index: 10;
      white-space: nowrap;

      .top-tag {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .bread-group-left {
      left: 0;
    }

    .bread-group-right {
      right: 0;
    }
  }
}
</style>
